import React from 'react';
import { createStyles, Text, Box } from '@mantine/core';

import Logo from 'components/Logo';

export default function Forbidden() {
    const { classes } = useStyles();

    return (
        <Box mx="auto" className={classes.container}>
            <Logo />
            <Text mt="md">Access Denied</Text>
        </Box>
    );
}

const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '256px',
    },
    loader: {
        marginTop: theme.spacing.lg,
    },
}));
